import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

function RubricBuilder({ rubric, onRubricChange, onSubmit, onBack }) {
  const [localRubric, setLocalRubric] = useState(rubric);
  const [isRubricSaved, setIsRubricSaved] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [error, setError] = useState('');

  const handleAddRow = () => {
    if (localRubric.some(row => row.some(cell => cell === ''))) {
      setError('Please fill out all cells before adding a new row.');
      return;
    }
    setLocalRubric([...localRubric, ['', '']]);
  };

  const handleAddColumn = (rowIdx) => {
    if (localRubric[rowIdx].length > 6) {
      setError('Cannot add more than 6 grade columns');
      return;
    }
    if (localRubric[rowIdx].some(cell => cell === '')) {
      setError('Please fill out all cells before adding a new column.');
      return;
    }
    const newRubric = localRubric.map((row, rIdx) =>
      rIdx === rowIdx ? [...row, ''] : row
    );
    setLocalRubric(newRubric);
  };

  const handleRemoveRow = (rowIdx) => {
    setLocalRubric(localRubric.filter((_, idx) => idx !== rowIdx));
  };

  const handleRemoveColumn = (rowIdx) => {
    if (localRubric[rowIdx].length > 2) {
      const newRubric = localRubric.map((row, rIdx) =>
        rIdx === rowIdx ? row.slice(0, -1) : row
      );
      setLocalRubric(newRubric);
    }
  };

  const handleCellChange = (rowIdx, colIdx, value) => {
    const newRubric = localRubric.map((row, rIdx) => (
      rIdx === rowIdx ? row.map((cell, cIdx) => (cIdx === colIdx ? value : cell)) : row
    ));
    setLocalRubric(newRubric);
  };

  const handleSave = () => {
    onRubricChange(localRubric);
    setIsRubricSaved(true);
    setSaveMessage('Rubric has been saved!');
    setTimeout(() => setSaveMessage(''), 3000); // Clear message after 3 seconds
  };

  const handleDownloadRubric = () => {
    const csvContent = localRubric.map(row => row.join('|')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'rubric.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUploadRubric = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const content = event.target.result;
      const newRubric = content.split('\n').map(row => row.split('|'));
      setLocalRubric(newRubric);
    };
    reader.readAsText(file);
  };

  const handleImportPremadeRubric = async () => {
    try {
      const response = await axios.get('https://essaiapi.com/get-premade-rubric');
      console.log('Response:', response);
      const csv = response.data;
      const newRubric = csv.split('\n').map(row => row.split('|'));
      setLocalRubric(newRubric);
    } catch (error) {
      console.error('Error importing rubric:', error);
      setError(`Failed to import premade rubric: ${error.message}`);
    }
  };

  const getMaxColumns = () => {
    return Math.max(...localRubric.map(row => row.length));
  };

  const handleSubmit = () => {
    if (!isRubricSaved) {
      setError('Please save the rubric before submitting.');
      return;
    }
    onSubmit();
  };

  return (
    <div className="RubricBuilder">
      <h1>Build Rubric</h1>
      <table>
        <thead>
          <tr>
            <th>Criteria Description</th>
            {Array.from({ length: getMaxColumns() - 1 }, (_, idx) => (
              <th key={idx}>Grade {idx + 1}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {localRubric.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {row.map((cell, colIdx) => (
                <td key={colIdx}>
                  <textarea
                    type="text"
                    value={cell}
                    onChange={(e) => handleCellChange(rowIdx, colIdx, e.target.value)}
                    style={{ width: colIdx === 0 ? '150px' : '100px' }} // Adjust width to fit the grid
                  />
                </td>
              ))}
              <td>
                <button onClick={() => handleAddColumn(rowIdx)}>+</button>
                <button onClick={() => handleRemoveColumn(rowIdx)}>-</button>
                <button onClick={() => handleRemoveRow(rowIdx)}>Remove Criteria</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow}>Add Criteria</button>
      <div className="rubric-buttons">
        <button onClick={handleDownloadRubric}>Export Rubric</button>
        <button onClick={() => document.getElementById('rubric-upload').click()}>Import Rubric</button>
        <input id="rubric-upload" type="file" onChange={handleUploadRubric} style={{ display: 'none' }} />
        <button onClick={handleImportPremadeRubric}>Import Premade Rubric</button>
      </div>
      <div className="submit-buttons">
        <button onClick={handleSave}>Save Rubric</button>
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={onBack}>Back</button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {saveMessage && <p style={{ color: 'green' }}>{saveMessage}</p>}
    </div>
  );
}

export default RubricBuilder;
