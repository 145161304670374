import React from 'react';

const formatText = (text) => {
  const formattedStrings = [
    { text: 'Criteria: ', style: 'color: gray; font-weight: bold; font-size: 1.8rem;' },
    { text: 'Score: ', style: 'color: gray; font-weight: bold; font-size: 1.8rem;' },
    { text: 'Reason: ', style: 'color: gray; font-weight: bold; font-size: 1.8rem;' },
    // Add more strings and styles as needed
  ];

  let formattedText = text.replace(/&/g, '&amp;')
                          .replace(/</g, '&lt;')
                          .replace(/>/g, '&gt;');

  formattedStrings.forEach(({ text, style }) => {
    const regex = new RegExp(text, 'g');
    formattedText = formattedText.replace(
      regex,
      `<span style="${style}">${text}</span>`
    );
  });

  return formattedText;
};

const TextFormatter = ({ text }) => {
  const formattedText = formatText(text);

  return (
    <pre className="result-text" dangerouslySetInnerHTML={{ __html: formattedText }} />
  );
};

export default TextFormatter;
