import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import TextFormatter from './TextFormatter';
import RubricBuilder from './RubricBuilder';

function App() {
  const [text, setText] = useState('');
  const [subject, setSubject] = useState('');
  const [prompt, setPrompt] = useState('');
  const [rubric, setRubric] = useState([['', '']]); // Initial rubric with one row and one column
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDots, setLoadingDots] = useState('.');
  const [showRubricBuilder, setShowRubricBuilder] = useState(false);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingDots((prev) => (prev.length === 3 ? '.' : prev + '.'));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleRubricChange = (newRubric) => {
    setRubric(newRubric);
  };

  const handleSubmit = async () => {

    if (!subject || !prompt || rubric.length < 1) {
      setError('Please provide all inputs');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('text', text);
    formData.append('subject', subject);
    formData.append('prompt', prompt);
    formData.append('rubric', JSON.stringify(rubric)); // Send rubric as JSON string

    try {
      const response = await axios.post('https://essaiapi.com/grade', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setResult(response.data.text);
      setError('');
    } catch (error) {
      console.error('There was an error!', error);
      setError('Network error. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFinalSubmit = () => {
    if (rubric.length === 0 || rubric[0].length === 0 || rubric[0][0] === '') {
      setError('Please complete the rubric before submitting.');
      return;
    }
    handleSubmit();
  };

  const handleBack = () => {
    setResult('');
  };

  const handleGoToRubricBuilder = () => {
    if (!text || !subject || !prompt) {
      setError('Please fill out all fields before proceeding to the rubric builder.');
      return;
    }
    setError('');
    setShowRubricBuilder(true);
  };

  if (loading) {
    return (
      <div className="App">
        <h1>Loading{loadingDots}</h1>
      </div>
    );
  }

  if (result) {
    return (
      <div className="App result-container">
        <h1>Result</h1>
        <TextFormatter text={result} />
        <button onClick={handleBack}>Back</button>
      </div>
    );
  }

  if (showRubricBuilder) {
    return (
      <RubricBuilder
        rubric={rubric}
        onRubricChange={handleRubricChange}
        onSubmit={handleFinalSubmit}
        onBack={() => setShowRubricBuilder(false)}
      />
    );
  }

  return (
    <div className="App">
      <h1>Essay Grader</h1>
      <input type="text" placeholder="Subject" value={subject} onChange={handleSubjectChange} />
      <textarea placeholder="Prompt" value={prompt} onChange={handlePromptChange} className="large-input" />
      <textarea placeholder="Essay" value={text} onChange={handleTextChange} className="large-textarea" />
      <button onClick={handleGoToRubricBuilder}>Build Rubric</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default App;
